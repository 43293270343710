import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"height":"500","width":"334"},model:{value:(_vm.createPhoneNumberRelationDialog),callback:function ($$v) {_vm.createPhoneNumberRelationDialog=$$v},expression:"createPhoneNumberRelationDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('addPhoneNumber')))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.createPhoneNumberRelationDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),(!_vm.activationCodeSent)?_c(VCardText,[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{ref:"phoneNumberTextfield",attrs:{"label":"Mobilnummer","rules":_vm.globalPhoneNumberValidator(_vm.phoneNumber),"type":"number"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c(VBtn,{attrs:{"disabled":!_vm.valid,"loading":_vm.working},on:{"click":function($event){return _vm.clickAddPhoneNumberRelation()}}},[_vm._v(" "+_vm._s(_vm.$t('basket-buttonConfirm'))+" ")])],1):_vm._e()],1),(_vm.errorDialog)?_c('error-dialog',{staticClass:"ma-0 pa-0",attrs:{"title-text":_vm.$t('eksportstatus-30'),"content-text":_vm.errorText,"content-sub-text":_vm.errorSubText,"confirm-button-text":_vm.$t('registrations-searchpopup-registration-closebutton')},on:{"confirm":function($event){_vm.errorDialog = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }