<template>
    <v-dialog
        v-model="createPhoneNumberRelationDialog"
        height="500"
        width="334"
    >
        <v-card>
            <v-toolbar
                flat
                dense
            >
                <v-toolbar-title>{{ $t('addPhoneNumber') }}</v-toolbar-title>
                <v-spacer />             
                <v-btn
                    icon
                    @click="createPhoneNumberRelationDialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text v-if="!activationCodeSent">
                <v-form
                    v-model="valid"
                >
                    <v-text-field
                        ref="phoneNumberTextfield" 
                        v-model="phoneNumber"
                        label="Mobilnummer"
                        :rules="globalPhoneNumberValidator(phoneNumber)"
                        type="number"
                    />
                </v-form>
                <v-btn
                    :disabled="!valid"
                    :loading="working"
                    @click="clickAddPhoneNumberRelation()"
                >
                    {{ $t('basket-buttonConfirm') }}
                </v-btn>
            </v-card-text>
        </v-card>
        <error-dialog
            v-if="errorDialog"
            class="ma-0 pa-0"
            :title-text="$t('eksportstatus-30')"
            :content-text="errorText"
            :content-sub-text="errorSubText"
            :confirm-button-text="$t('registrations-searchpopup-registration-closebutton')"
            @confirm="errorDialog = false"
        />
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'


export default {
    components: {
        ErrorDialog: () => import('@/views/registration/ErrorDialog.vue')
    },
    data() {
        return {
            createPhoneNumberRelationDialog: false,
            activationCodeSent: false,
            phoneNumber: '',

            errorDialog: false,
            errorText: null,
            errorSubText: null,

            valid: false,
            working: false
        }
    },
    computed: {
    },
    watch: {
        createPhoneNumberRelationDialog: function(val) {
            if (!val) {
                this.$emit('close')
            }
        },
        phoneNumber(value){
            this.phoneNumber = value.replace(/\D+/,'')
        }
    },
    created() {        
        this.createPhoneNumberRelationDialog = true

        setTimeout(() => {
            this.$nextTick(() => {
                this.$refs.phoneNumberTextfield.$refs.input.focus()
            }), 500})
    },    
    methods: {
        clickAddPhoneNumberRelation() {
            this.working = true
            this.FetchCreateCustomerPrivateRegistrationRelation({
                registrationNumber: this.phoneNumber,
                vehicleBrand: this.vehicleBrand,
                vehicleModel: this.vehicleModel,
                relationType: 1
            }).then(result => {
                if(result.codes.find(x => x === 'relationalreadypresentonothercustomer')){
                    this.errorText = result.codes.find(x => x === 'relationalreadypresentonothercustomer')
                    if (this.errorText) {
                        this.errorText = this.$t('relationAlreadyExists')
                    }
                    this.working = false
                    this.errorDialog = true
                }
                else {
                    this.$emit('close')
                }
            })
        },
        ...mapActions({
            FetchCreateCustomerPrivateRegistrationRelation: 'FetchCreateCustomerPrivateRegistrationRelation'
        })
    },
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>